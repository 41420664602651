/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "focus-visible/dist/focus-visible"
import "./src/fonts/fonts.css"


// You can delete this file if you're not using it
import { wrapPageElement as wrap } from "./src/page_wrapper"

export const wrapPageElement = wrap