export const links = [
  {
    label: 'Docs',
    href: '#',
  },
  {
    label: 'Guides',
    href: '#',
  },
  {
    label: 'Features',
    href: '#',
  },
  {
    label: 'Download',
    href: '#',
  },
  {
    label: 'Blog',
    href: '#',
    isExternal: true
  },
  {
    label: 'Github',
    href: 'https://github.com/batchcorp/plumber',
    isExternal: true,
  },
]