import React from 'react'
import { chakra, useColorModeValue } from '@chakra-ui/react'


const HamburgerIcon = (props) => {
  const fillColor = useColorModeValue('#372D56', '#FFFFFF')
  return (
    <chakra.svg aria-hidden viewBox="0 0 19 12" fill={fillColor} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.993652 2V0H18.9937V2H0.993652ZM0.993652 7H18.9937V5H0.993652V7ZM0.993652 12H18.9937V10H0.993652V12Z"
        fill={fillColor}
      />
    </chakra.svg>
  )
}

const CloseIcon = (props) => {
  const fillColor = useColorModeValue('#372D56', '#FFFFFF')
  return (
    <chakra.svg aria-hidden viewBox="0 0 14 14" fill={fillColor} {...props}>
      <path
        d="M1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893C-0.097631 0.683417 -0.0976312 1.31658 0.292893 1.70711L5.5858 7.00002L0.292922 12.2929C-0.0976021 12.6834 -0.0976021 13.3166 0.292922 13.7071C0.683446 14.0976 1.31661 14.0976 1.70714 13.7071L7.00001 8.41423L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41423 7.00002L13.7071 1.70711C14.0977 1.31658 14.0977 0.683419 13.7071 0.292895C13.3166 -0.0976298 12.6834 -0.0976294 12.2929 0.292895L7.00001 5.5858L1.70711 0.292893Z"
        fill={fillColor}
      />
    </chakra.svg>
  )
}

const DarkModeIcon = (props) => {
  const fillColor = useColorModeValue('#372D56', '#FFFFFF')
  return (
    <chakra.svg
      aria-hidden
      viewBox="0 0 12 12"
      fill={fillColor}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.483 8.25273C11.6926 7.76638 11.2365 7.29967 10.7069 7.29967C7.66878 7.29967 5.20587 4.83677 5.20587 1.79862C5.20587 1.26946 4.73948 0.813817 4.25352 1.02324C2.29723 1.86633 0.927734 3.81197 0.927734 6.07747C0.927734 9.11562 3.39064 11.5785 6.42878 11.5785C8.69429 11.5785 10.6399 10.209 11.483 8.25273Z"
        fill={fillColor}
      />
    </chakra.svg>
  )
}

const LightModeIcon = (props) => {
  const fillColor = useColorModeValue('#372D56', 'bolt')
  return (
    <chakra.svg
      aria-hidden
      viewBox="0 0 12 13"
      fill={fillColor}
      {...props}
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M5.56176 9.70749C7.31636 9.70749 8.73874 8.28511 8.73874 6.53051C8.73874 4.7759 7.31636 3.35352 5.56176 3.35352C3.80715 3.35352 2.38477 4.7759 2.38477 6.53051C2.38477 8.28511 3.80715 9.70749 5.56176 9.70749Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M5.55888 0.971191C5.85131 0.971191 6.08838 1.20826 6.08838 1.50069V2.03019C6.08838 2.32262 5.85131 2.55969 5.55888 2.55969C5.26644 2.55969 5.02938 2.32262 5.02938 2.03019V1.50069C5.02938 1.20826 5.26644 0.971191 5.55888 0.971191Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M5.55888 10.5022C5.85131 10.5022 6.08838 10.7392 6.08838 11.0317V11.5594C6.08838 11.8519 5.85131 12.0889 5.55888 12.0889C5.26644 12.0889 5.02938 11.8519 5.02938 11.5594V11.0317C5.02938 10.7392 5.26644 10.5022 5.55888 10.5022Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6.53007C3.48724e-09 6.23764 0.237064 6.00057 0.529498 6.00057H1.059C1.35143 6.00057 1.58849 6.23764 1.58849 6.53007C1.58849 6.8225 1.35143 7.05957 1.059 7.05957H0.529498C0.237064 7.05957 -3.48724e-09 6.8225 0 6.53007Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M9.53097 6.53007C9.53097 6.23764 9.76803 6.00057 10.0605 6.00057H10.5883C10.8807 6.00057 11.1178 6.23764 11.1178 6.53007C11.1178 6.8225 10.8807 7.05957 10.5883 7.05957H10.0605C9.76803 7.05957 9.53097 6.8225 9.53097 6.53007Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M1.62821 10.4608C1.42143 10.254 1.42143 9.91874 1.62821 9.71196L2.00262 9.33755C2.2094 9.13077 2.54466 9.13077 2.75144 9.33755C2.95823 9.54433 2.95823 9.87959 2.75144 10.0864L2.37703 10.4608C2.17025 10.6676 1.83499 10.6676 1.62821 10.4608Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M8.36762 3.72137C8.16084 3.51459 8.16084 3.17933 8.36762 2.97255L8.74083 2.59935C8.94761 2.39256 9.28287 2.39256 9.48965 2.59935C9.69643 2.80613 9.69643 3.14139 9.48965 3.34817L9.11645 3.72137C8.90966 3.92816 8.5744 3.92816 8.36762 3.72137Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M1.62821 2.59935C1.83499 2.39256 2.17025 2.39256 2.37703 2.59935L2.75144 2.97376C2.95823 3.18054 2.95823 3.5158 2.75144 3.72258C2.54466 3.92936 2.2094 3.92936 2.00262 3.72258L1.62821 3.34817C1.42143 3.14139 1.42143 2.80613 1.62821 2.59935Z"
            fill={fillColor}
      />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M8.36762 9.33876C8.5744 9.13198 8.90966 9.13198 9.11645 9.33876L9.48965 9.71196C9.69643 9.91874 9.69643 10.254 9.48965 10.4608C9.28287 10.6676 8.94761 10.6676 8.74083 10.4608L8.36762 10.0876C8.16084 9.8808 8.16084 9.54554 8.36762 9.33876Z"
            fill={fillColor}
      />

    </chakra.svg>

  )
}

const GithubIcon = (props) => {
  let fillColor
  const color = useColorModeValue('#372D56', 'white')
  const { fill } = props

  if (fill === undefined) {
    fillColor = color
  } else {
    fillColor = props.fill
  }


  return (
    <chakra.svg viewBox="0 0 56 54" {...props}>
      <path
        d="M27.9969 0C12.9017 0 0.660156 12.2894 0.660156 27.4495C0.660156 39.5749 8.49311 49.8616 19.3608 53.4944C20.7278 53.7473 21.2268 52.8998 21.2268 52.1718C21.2268 51.5191 21.2029 49.7932 21.1892 47.5035C13.5852 49.161 11.979 43.8228 11.979 43.8228C10.7384 40.6514 8.94422 39.8073 8.94422 39.8073C6.45969 38.1053 9.12877 38.1395 9.12877 38.1395C11.873 38.3343 13.3152 40.9692 13.3152 40.9692C15.7553 45.1625 19.7162 43.9527 21.2746 43.2487C21.5207 41.475 22.2281 40.2652 23.0107 39.5783C16.9412 38.8845 10.5573 36.5299 10.5573 26.0142C10.5573 23.017 11.6236 20.5666 13.3733 18.646C13.0897 17.9522 12.1533 15.1601 13.6399 11.3838C13.6399 11.3838 15.9365 10.6456 21.1584 14.1964C23.3388 13.5881 25.6764 13.2839 28.0037 13.2736C30.3242 13.2873 32.6652 13.5881 34.849 14.1998C40.0676 10.649 42.3607 11.3872 42.3607 11.3872C43.8508 15.1669 42.9144 17.9556 42.6341 18.6494C44.3873 20.57 45.4433 23.0204 45.4433 26.0176C45.4433 36.5606 39.0526 38.8811 32.9625 39.5612C33.9434 40.4087 34.8183 42.0833 34.8183 44.643C34.8183 48.3135 34.7841 51.273 34.7841 52.1718C34.7841 52.9066 35.2762 53.761 36.6637 53.491C47.5143 49.8548 55.3405 39.5714 55.3405 27.4495C55.3405 12.2894 43.0989 0 27.9969 0Z"
        fill={fillColor}
      />
    </chakra.svg>
  )
}
export const MobileIcons = {
  HamburgerIcon,
  CloseIcon,
  DarkModeIcon,
  LightModeIcon,
  GithubIcon
}