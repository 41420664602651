import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorMode,
} from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'
import { MobileIcons } from './NavIcons'
import { links } from './_data'

const MobileNavContext = (props) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <Flex
        {...props}
        h="100%"
        align="center"
        justify="space-between"
        className="nav-content__mobile"
      >
        <Box as="a" rel="home">
          <Logo
            h={["28px", null, "20px"]}
            paddingLeft={["0", "0", "30px"]}
            id0="8f5cbefq"
            id1="8f5cbef"
            id2="8f5cbe"
            id3="8f5cb"
            id4="8f5c"
            id5="8f5"
            id6="8f"
          />
        </Box>
        <Flex align="center">
          <IconButton
            display={{
              base: 'flex',
              lg: 'none',
            }}
            mx="15px"
            alignItems='center'
            aria-label="Open menu"
            variant="unstyled"
            onClick={toggleColorMode}
            icon={colorMode === 'light' ?
              <MobileIcons.DarkModeIcon w={["15px"]} h={["15px"]}/> : <MobileIcons.LightModeIcon w={["20px"]} h={["18px"]} />}
          />
          <IconButton
            display={{
              base: 'flex',
              lg: 'none',
            }}
            size="sm"
            alignItems='center'
            aria-label="Open menu"
            variant="unstyled"
            onClick={onToggle}
            icon={isOpen ? <MobileIcons.CloseIcon w="15px"/> : <MobileIcons.HamburgerIcon w="20px"/>} />
        </Flex>
      </Flex>
      <NavMenu animate={isOpen ? 'open' : 'closed'} zIndex={9000}>
        {links.map((link, idx) => {
            if (link.label === 'Github') {
             return (
               <NavLink.Mobile key={idx} href={link.href}>
                {<MobileIcons.GithubIcon w="54px" h="53px" m="margin-bottom: 37px" fill={"#372D56"}/>}
              </NavLink.Mobile>
             )
            } else {
              return (
                <NavLink.Mobile key={idx} href={link.href}>
                  {link.label}
                </NavLink.Mobile>
              )
            }
        })}
      </NavMenu>
    </>
  )
}

const DesktopNavContent = (props) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Flex
      className="nav-content__desktop"
      align="center"
      justifyContent="space-between"
      h={[null, null, null, "inherit"]}
      {...props}
    >
      <Box as="a" href="#" rel="home">
        <Logo h={["28px", null, "20px", "28px"]} />
      </Box>
      <HStack
        as="ul"
        id="nav__primary-menu"
        aria-label="Main Menu"
        listStyleType="none"
        gridColumnGap="39px"
      >
        {links.map((link, idx) => {
          if (link.label === 'Github') {
            return (
              <Box
                as="li"
                key={idx}
                id={`nav__menuitem-${idx}`}
                mx={["0"]}
              >
                <NavLink.Desktop  href={link.href}>
                  {<MobileIcons.GithubIcon
                    w={["22px", null, null, null, "24px"]}
                    h={["21px", null, null, null, "23px"]} />}
                </NavLink.Desktop>
              </Box>
            )
          } else {
            return (
              <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
                <NavLink.Desktop href={link.href}>
                  {link.label}
                </NavLink.Desktop>
              </Box>
            )
          }
        })}
      </HStack>
      <IconButton
        size={[null, null, null, "20px"]}
        h={[null, null, null, "auto"]}
        mx={["15px", null, null, "0"]}
        alignItems='center'
        aria-label="Open menu"
        variant="unstyled"
        onClick={toggleColorMode}
        icon={colorMode === 'light' ?
          <MobileIcons.DarkModeIcon w={["20px"]} h={["auto"]}/> : <MobileIcons.LightModeIcon w={["20px"]} h={["auto"]} />}
      />
    </Flex>
  )
}

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
}
