import { useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { MotionBox } from './MotionBox'

const lightBackground = "linear-gradient(0deg, #C1AAFD, #C1AAFD), linear-gradient(95.79deg, #E45D33 -3.17%, #F2ACAA 145.44%);"
const darkBackground = "linear-gradient(0deg, #FFD260, #FFD260), linear-gradient(95.79deg, #E45D33 -3.17%, #F2ACAA 145.44%)"
export const NavMenu = React.forwardRef((props, ref) => (
  <MotionBox
    {...props}
    ref={ref}
    initial="init"
    variants={variants}
    outline="0"
    opacity="0"
    bg={useColorModeValue(lightBackground, darkBackground)}
    w="full"
    h="100vh"
    pos="absolute"
    insetX="0"
  />
))

NavMenu.displayName = 'NavMenu'

const variants = {
  init: {
    opacity: 0,
    y: -4,
    display: 'none',
    transition: {
      duration: 0,
    },
  },
  open: {
    opacity: 1,
    y: 0,
    display: 'block',
    transition: {
      duration: 0.15,
    },
  },
  closed: {
    opacity: 0,
    y: -4,
    transition: {
      duration: 0.1,
    },
    transitionEnd: {
      display: 'none',
    },
  },
}