// src/@chakra-ui/gatsby-plugin/theme.js
// gatsby shadow
import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  sm: "428px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1440",
  "3xl": "1600",
  "4xl": "1920",
})

const theme = {
  colors: {
    web: "#372D56",
    stream: "#C1AAFD",
    bolt: "#FFD260",
    data: "#F7E7CC",
    cloud: "#FFFFFF",
    toggle: "#BDA6F8",
    fontIllustration: "#2B2343",
  },
  fonts: {
    heading: "Space GroteskVariable",
    body: "InterVariable"
  },
  initialColorMode: "light",
  useSystemColorMode: false,
  breakpoints,
}

export default extendTheme(theme)